<template>
  <LLayoutMortgage>
    <v-container class="pt-0 px-4 max-width">
      <v-row>
        <v-col cols="12">
          <h1 class="max-width hero">
            {{ MORTGAGE_HEADER_ONE.TITLE }}
            <br>
            {{MORTGAGE_HEADER_ONE.SUBTITLE}}
          </h1>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="pt-0 px-4 max-width">
      <ComparerWidgetMainContent :utms="allUtms" :type="ProductType.MORTGAGE" class="sm:mt-2 px-4 md:mt-3 lg:mt-4"/>
    </v-container>
    <v-container class="max-width">
      <h2>
        Jak odczytywać wyniki z kalkulatora raty hipotecznej?
      </h2>
      <p>
        W kalkulatorze rat kredytów hipotecznych istnieje możliwość porównania ofert pod różnymi kątami. Istotne jest przede wszystkim spojrzenie na koszt całkowity. Różnice między ofertami mogą sięgać nawet kilkudziesięciu tysięcy złotych. Dlatego wyniki symulacji są uporządkowane według rosnącego kosztu całkowitego kredytu. Dodatkowo, warto zestawić wyniki pod względem wysokości miesięcznych rat, aby ocenić, jakie obciążenie finansowe poniesiemy miesięcznie. Należy również zwrócić uwagę na wysokość RRSO, co może dostarczyć dodatkowych informacji.
        <br>
        Należy pamiętać, że parametry podane przez kalkulator rat kredytu hipotecznego są jedynie symulacją i mogą różnić się od aktualnych ofert przygotowywanych przez banki, które uwzględniają, między innymi, zdolność kredytową klienta. Dlatego uzyskane wyniki należy traktować wyłącznie orientacyjnie, gdyż ostateczne warunki wybranego kredytu hipotecznego mogą być inne. Aby uzyskać wiążące oferty, zaleca się kontakt z konkretnymi bankami.
      </p>
    </v-container>
    <v-container class="max-width">
      <PageRating class="mb-8" :rating-type="RatingType.ComparerWidget" label="Oceń porównywarkę!"
                   :meta-title="metaTitle"
                   :meta-description="metaDescription"/>
      <v-row class="mb-4 max-md:mx-3">
        <v-col cols="12" md="6">
          <h3 class="mb-6">
            <a class="headline-2" href="https://www.lendi.pl/kalkulator-zdolnosci-kredytowej">
              Najszybszy sposób na najtańszy kredyt
            </a>
          </h3>
          <p class="mb-3 text--primary  !font-medium">
            <v-icon size="18" class="mr-1">
              {{ mdiCheck }}
            </v-icon>Porównujesz oferty i sprawdzasz zdolność kredytową bez wychodzenia z domu
          </p>
          <p class="mb-3 text--primary  !font-medium">
            <v-icon size="18" class="mr-1">
              {{ mdiCheck }}
            </v-icon>Wypełniasz jeden formularz i składasz wnioski do kilku banków jednocześnie
          </p>
          <p class="mb-3 text--primary  !font-medium">
            <v-icon size="18" class="mr-1">
              {{ mdiCheck }}
            </v-icon>Podpisujesz umowę z bankiem, który zaoferuje Ci najtańszy kredyt
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <nuxt-img loading="lazy" height="300px" width="350px" class="max-w-[350px] w-full" alt="Najszybszy sposób na najtańszy kredyt"
                 src="/imgs/kredyty-hipoteczne/najszybszysposob.webp" />
        </v-col>
      </v-row>
      <v-row class="mb-4 flex-row-reverse max-md:mx-3">
        <v-col cols="12" md="6">
          <h3 class="mb-6">
            <a class="headline-2" href="https://www.lendi.pl/blog/ranking-kredytow-hipotecznych">
              Porównanie kredytów hipotecznych z {{ numberOfBanks[ProductType.MORTGAGE] }} wiodących banków
            </a>
          </h3>
          <p class="mb-3 text--primary  !font-medium">
            <v-icon size="18" class="mr-1">
              {{ mdiCheck }}
            </v-icon>Porównujesz tylko aktualne oferty wiodących banków
          </p>
          <p class="mb-3 text--primary  !font-medium">
            <v-icon size="18" class="mr-1">
              {{ mdiCheck }}
            </v-icon>Sprawdzasz, jakie oferty kredytu są w Twoim zasięgu
          </p>
          <p class="mb-3 text--primary  !font-medium">
            <v-icon size="18" class="mr-1">
              {{ mdiCheck }}
            </v-icon>Za darmo konsultujesz ofertę z niezależnym Ekspertem Finansowym
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <nuxt-img loading="lazy" height="300px" width="350px"
                    class="max-w-[350px] w-full" alt="Sprawdzone oferty" src="/imgs/kredyty-hipoteczne/sprawdzoneoferty.webp" />
        </v-col>
      </v-row>
      <v-row class="mb-4 max-md:mx-3 max-md:mx-3">
        <v-col cols="12" md="6">
          <h3 class="mb-6">
            <a class="headline-2" href="https://www.lendi.pl/eksperci-finansowi">Darmowe wsparcie eksperta na każdym kroku</a>
          </h3>
          <p class="mb-3 text--primary  !font-medium">
            <v-icon size="18" class="mr-1">
              {{ mdiCheck }}
            </v-icon>W zakupie i finansowaniu nieruchomości pomoże Ci doświadczony Ekspert Finansowy
          </p>
          <p class="mb-3 text--primary  !font-medium">
            <v-icon size="18" class="mr-1">
              {{ mdiCheck }}
            </v-icon>Jego wynagrodzenie pokryje bank, którego ofertę wybierzesz
          </p>
          <p class="mb-3 text--primary  !font-medium">
            <v-icon size="18" class="mr-1">
              {{ mdiCheck }}
            </v-icon>Konsultację odbędziesz w biurze Eksperta lub przez Internet
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <nuxt-img
              loading="lazy" height="300px" width="350px"
              class="max-w-[350px] w-full" alt="Daromowe wsparcie" src="/imgs/kredyty-hipoteczne/darmowewsparcie.webp" />
        </v-col>
      </v-row>
      <v-row class="mb-4 flex-row-reverse max-md:mx-3">
        <v-col cols="12" md="6">
          <h3 class="mb-6">
            <a class="headline-2" href="https://www.lendi.pl/blog">Przydatne i aktualne porady dla kredytobiorców</a>
          </h3>
          <p class="mb-3 text--primary  !font-medium">
            <v-icon size="18" class="mr-1">
              {{ mdiCheck }}
            </v-icon>Blog z wyjaśnieniem najważniejszych elementów kredytu hipotecznego
          </p>
          <p class="mb-3 text--primary  !font-medium">
            <v-icon size="18" class="mr-1">
              {{ mdiCheck }}
            </v-icon>Informacje co musisz zrobić, żeby otrzymać kredyt hipoteczny
          </p>
          <p class="mb-3 text--primary  !font-medium">
            <v-icon size="18" class="mr-1">
              {{ mdiCheck }}
            </v-icon>Artykuły blogowe wskazujące jak się zachować, kiedy już uzyskasz kredyt hipoteczny
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <nuxt-img
              loading="lazy" height="300px" width="350px"
              alt="Porady dla kredytobiorców" class="border-radius-12 max-w-[350px] w-full" src="/imgs/kredyty-hipoteczne/morgagetips.webp" />
        </v-col>
      </v-row>
      <v-row class="mb-4 max-md:mx-3">
        <v-col cols="12" md="6">
          <h3 class="mb-6">
            <a class="headline-2" href="https://www.lendi.pl">Wszystkie potrzebne wyliczenia w jednym miejscu</a>
          </h3>
          <p class="mb-3 text--primary  !font-medium">
            <v-icon size="18" class="mr-1">
              {{ mdiCheck }}
            </v-icon>Kalkulator zdolności kredytowej wskaże, czy bank udzieli Ci kredytu
          </p>
          <p class="mb-3 text--primary  !font-medium">
            <v-icon size="18" class="mr-1">
              {{ mdiCheck }}
            </v-icon>Sprawdzisz wszystkie aktualne oferty banków w kalkulatorze rat kredytów hipotecznych
          </p>
          <p class="mb-3 text--primary  !font-medium">
            <v-icon size="18" class="mr-1">
              {{ mdiCheck }}
            </v-icon>Zweryfikujesz czy masz szansę uzyskać rządową pomoc korzystając z kalkulatora mieszkania bez wkładu
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <nuxt-img
              loading="lazy" height="300px" width="350px"
              alt="Wyliczenia" class="border-radius-12 max-w-[350px] w-full" src="/imgs/kredyty-hipoteczne/calculations.webp" />
        </v-col>
      </v-row>
      <v-row class="mb-4 max-md:mx-3">
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <p class="headline-1">5 511 923 275 zł</p>
              <span class="headline-3">pomogliśmy pozyskać naszym klientom w 2021 r.</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-text class="text-center">
              <nuxt-img loading="lazy" max-width="40" class="mb-2 mx-auto" alt="Ikona home-heart" src="/imgs/kredyty-hipoteczne/home-heart.webp" />
              <p class="headline-4">
                14 961 spełnionych marzeń <br/> o własnym domu
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-text class="text-center">
              <nuxt-img loading="lazy" max-width="40" class="mb-2 mx-auto" alt="Ikona piggy-bank" src="/imgs/kredyty-hipoteczne/piggy-bank.webp" />
              <p class="headline-4">
                29 226 osób uzyskało dzięki <br/> nam najtańszy kredyt hipoteczny
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-text class="text-center">
              <nuxt-img loading="lazy" max-width="40" class="mb-2 mx-auto" alt="Ikona video-account" src="/imgs/kredyty-hipoteczne/video-account.webp" />
              <p class="headline-4">
                Ponad 1600 ekspertów finansowych <br/> dostępnych online
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-text class="text-center">
              <nuxt-img loading="lazy" max-width="40" class="mb-2 mx-auto" alt="Ikona store" src="/imgs/kredyty-hipoteczne/store.webp" />
              <p class="headline-4">
                90 placówek otwartych <br/> w całej Polsce
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-9 mb-3">
        <v-col cols="12">
          <v-divider />
        </v-col>
      </v-row>
      <h2>
        Poradnik kredytobiorcy
      </h2>
      <p class="mb-6">
        Jesteś kredytobiorcą lub przymierzasz się do tego, by wziąć kredyt hipoteczny? Z pewnością dręczy Cię wiele wątpliwości
        dotyczących zarówno zaciągnięcia zobowiązania, dopełnienia obowiązków związanych z wnioskowaniem o bankowe finansowanie,
        jak i obejmujących kształtowanie się wysokości odsetek czy innych opłat. Dlatego dostarczamy Ci poradnik, który pozwoli znaleźć
        odpowiedzi na najbardziej nurtujące pytania związane z procesem kredytowania.
      </p>
      <LExpansionPanel :items="guideItems" item-title="question" item-text="answer" class="mb-12"/>
      <v-row class="mt-9 mb-3">
        <v-col>
          <v-divider />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h2 class="mb-6">
            Często zadawane pytania
          </h2>
          <LExpansionPanel :items="KredytyHipoteczneFAQ" :useJsonLD="false" item-title="question" item-text="answer" class="mb-12"/>
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-3">
        <v-col>
          <v-divider />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <ArticleList/>
        </v-col>
      </v-row>
      <v-row class="mt-9 mb-3">
        <v-col>
          <v-divider />
        </v-col>
      </v-row>
      <v-row class="py-4">
        <v-col>
          <h2 class="pb-2">
            Ile wyniesie miesięczna rata kredytu hipotecznego przy oprocentowaniu 5,95%?
          </h2>
          <v-table
              :headers="monthlyInstallmentHeaders"
              :items="monthlyInstallmentItems"
              disable-sort
              :hide-default-footer="true"
              item-key="name"
              class="whitespace-nowrap">
            <thead>
            <tr>
              <th class="text-left" :key="header.text" v-for="header in monthlyInstallmentHeaders">
                {{ header.text }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="item in monthlyInstallmentItems"
                :key="item.name"
            >
              <td>{{ item.name }}</td>
              <td>{{ item.years_10 }}</td>
              <td>{{ item.years_15 }}</td>
              <td>{{ item.years_20 }}</td>
              <td>{{ item.years_25 }}</td>
              <td>{{ item.years_30 }}</td>
              <td>{{ item.years_35 }}</td>
            </tr>
            </tbody>
          </v-table>
        </v-col>
      </v-row>
<!--      <v-row>-->
<!--        <v-col class="mb-12">-->
<!--          <h3>Przykład reprezentatywny</h3>-->
<!--          <p>Jako przykład reprezentatywny, rozważmy sytuację trzyosobowej rodziny, która planuje zaciągnąć kredyt hipoteczny. Kredyt na kwotę 300 000 zł rozłożony na 30 lat, przy wkładzie własnym wynoszącym 25%. Oprocentowanie stałe przez pierwsze 5 lat wynosi 0%, prowizja to 8,37%, a RRSO 2,137%. Miesięczna rata wynosi 2137 zł.</p>-->
<!--        </v-col>-->
<!--      </v-row>-->
<!--      <v-row>-->
<!--        <v-col>-->
<!--          <credit-process-steps/>-->
<!--        </v-col>-->
<!--      </v-row>-->
    </v-container>
  </LLayoutMortgage>
</template>

<script lang="ts" setup>
import {mdiCheck} from '@mdi/js';
import {useFAQ} from '~/service/jsonLd/FAQ/useFAQ';
import {useBorrowersGuide} from '~/service/jsonLd/FAQ/borrowersGuide';
import {useMonthlyInstallmentExample} from '~/service/jsonLd/FAQ/monthlyInstallmentExample';
import ComparerWidgetMainContent from '~/components/widgets/ComparerWidget/ComparerWidgetMainContent.vue';
import {useMortgageSimpleSimulationStore} from '~/models/simulation/simpleSimulation/SimpleSimulationInput';
import LExpansionPanel from '~/components/LExpansionPanel.vue';
import {ProductType} from '~/enums/ProductType';
import PageRating from '~/components/commons/PageRating.vue';
import {RatingType} from '~/models/Rating';
import {MORTGAGE_META} from '~/constants/pageConstants/pageMeta/MortgageMeta';
import {MORTGAGE_HEADER_ONE} from '~/constants/pageConstants/pageHeaders/MortgageHeader';
import type {LendiQuery, Utms} from "~/models/UTMs/UTMs";
import useWidgetUtmsApi from "~/composables/useWidgetUtmsApi";
import {Widgets} from "~/models/WidgetPartnerDict";
import {useQuery} from "~/composables/useQuery";

const metaTitle = 'Porównywarka kredytów hipotecznych | Lendi.pl' as const;
const metaDescription: string = 'Szukasz najlepszego kredytu hipotecznego? Sprawdź wysokość raty, porównaj aktualne oferty banków ' +
'i znajdź najlepszy kredyt hipoteczny z pomocą eksperta! Skorzystaj z kalkulatora rat kredytu hipotecznego ' +
'przygotowanego przez ekspertów finansowych.';

definePageMeta({
  middleware: [
    'query-guard-mortgage',
  ],
});

useLHead({
  title: MORTGAGE_META.TITLE,
  description: MORTGAGE_META.DESCRIPTION,
});

const { KredytyHipoteczneFAQ, } = useFAQ();

const {query,} = useQuery<LendiQuery>();
if (Object.keys(query).length > 0) {
  useNoindexNoFollowMeta();
}
const {getWidgetUtmsByKey,} = useWidgetUtmsApi();
const widgetUtms = await getWidgetUtmsByKey(Widgets.MORTGAGE_COMPARER);

const allUtms = ref<Partial<Utms>>({...widgetUtms, ...query,});

const {monthlyInstallmentItems, monthlyInstallmentHeaders,} = useMonthlyInstallmentExample();
const {initStore, setHypothecValue, setLoanPeriod} = useMortgageSimpleSimulationStore();

const {createMortgageComparerSchema} = await useSchema();
if (!process.client) {
  useJsonld(createMortgageComparerSchema(RatingType.ComparerWidget));
}

const numberOfBanks = 0;

const guideItems = useBorrowersGuide();

initStore();

useLHead({
  title: MORTGAGE_META.TITLE,
  description: MORTGAGE_META.DESCRIPTION,
});
</script>

<style scoped>
.border-radius-12 {
  border-radius: 12px!important;
}
.border-radius-12-left {
  border-bottom-left-radius: 12px!important;
  border-top-left-radius: 12px !important ;
}
.border-outlined {
  border:thin solid rgba(35, 45, 58, 0.12) !important;
}
.max-width {
  max-width: 950px !important;
}
</style>
